<template>
  <div>
    <div class="found-template" v-if="!notFound">

      <h1 class="text-center" v-if="pageTitle">{{ pageTitle }} - email template</h1>

      <p>{{ description }}</p>

      <h3>Instructions:</h3>

      <ol class="instructions-list">
        <li v-if="recipientOptions.length">Choose a <span>{{ recipientMeta ? recipientMeta.label : 'recipient' }}</span> from the options below</li>
        <li v-if="placeholders.length">Simply fill in all the placeholder inputs below.</li>
        <li>Click "create email" button below</li>
        <li>Your default device email application should open up with the template filled and the placeholder values replaced with the values you have added (if any).</li>
        <li>Click send in your email application!</li>
      </ol>

      <div class="recipients" v-if="recipientOptions.length">
        <v-autocomplete
          v-model="recipientOptionValue"
          :items="recipientOptions"
          :loading="searchLoading"
          item-text="label"
          clearable
          :placeholder="`Start typing to find a ${recipientMeta ? recipientMeta.label : 'recipient'}.`"
         >
         <template v-slot:label>
           Select a {{ recipientMeta ? recipientMeta.label : 'recipient' }}
         </template>
        </v-autocomplete>

      </div>

      <div class="placeholders" v-if="placeholders.length">
        <h4>Placeholders:</h4>
        <!-- <p>Should check if to is an array or string, if array should allow users to select which person</p> -->
        <TextInput
          v-for="(placeholder, index) of placeholders"
          :key="placeholder"
          :id="`placeholder-${index}`"
          :label="`${placeholder}`"
          @input="updatePlaceholder(placeholder, $event)"
        />
      </div>
      <div class="link text-center mb-5">
        <v-btn
          @click="trackEmailClick"
          :href="emailLink"
          :disabled="emailBtnLinkDisabled"
          x-large
          color="primary"
          class="btn btn-primary btn-lg"
          target="_blank"
          rel="noopener
          noreferrer"
        >Create Email
        </v-btn>
      </div>
      <div class="message">
        <v-sheet
            elevation="12"
            class="pa-12"
          >
        <pre>
          {{ this.messageWithPlaceholdersFilled }}
        </pre>
        </v-sheet>
      </div>
      <div id="rendered" v-if="rendered"></div>
    </div>
    <div v-else>
      <NotFound />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { analytics, firestore, templatedViewed } from '@/plugins/firebase'
import getPlaceholders from '@/utils/get-placeholders'
import NotFound from '@/views/NotFound'

/* global templateObj */

export default Vue.extend({
  name: 'Template',

  metaInfo () {
    return {
      title: this.pageTitle
    }
  },

  data () {
    return {
      template: {},
      placeholderToValue: {},
      isLoading: true,
      rendered: false,
      notFound: false,
      recipientOptionValue: '',
      search: null,
      searchLoading: false,
      recipientTemplate: {}
    }
  },

  mounted () {
    this.initTemplate()
  },

  computed: {
    templateId () {
      return this.$route.params.templateId
    },
    templateExists () {
      return Object.keys(this.template).length > 1
    },
    pageTitle () {
      return this.template.title ?? ''
    },
    description () {
      return this.template.description ?? ''
    },
    message () {
      return this.template.message ?? ''
    },
    hasRecipientOptions () {
      return !!this.template.recipient_options
    },
    hasRecipientTemplate () {
      return !!this.template.recipient_template
    },
    recipientOptions () {
      if (!this.recipientTemplate.recipients) return []
      return Object.values(this.recipientTemplate.recipients)
    },
    recipientMeta () {
      if (!this.recipientTemplate.meta) return {}
      return this.recipientTemplate.meta
    },
    to () {
      if (this.hasRecipientOptions || this.hasRecipientTemplate) {
        return this.recipientOptionValue
      }
      return this.template.to
    },
    placeholders () {
      return getPlaceholders(this.message)
    },
    messageWithPlaceholdersFilled () {
      let text = String(this.message)

      Object.keys(this.placeholderToValue).forEach(placeholder => {
        const replace = this.placeholderToValue[placeholder] || placeholder
        const regex = new RegExp(placeholder, 'gi')
        text = text.replace(regex, replace)
      })
      return text
    },
    emailLink () {
      if (this.emailBtnLinkDisabled) return ''

      const to = this.to
      const cc = this.template.cc ?? ''
      const subject = this.template.subject
      const body = this.messageWithPlaceholdersFilled

      const queryStringObj = {
        cc,
        subject,
        body
      }

      const queryString = this.objToQueryString(queryStringObj)

      return `mailto:${to}?${queryString}`
    },
    emailBtnLinkDisabled () {
      if (!this.templateExists) {
        return true
      }
      return !this.placeholders.every(placeholder => {
        return this.placeholderToValue[placeholder] && this.placeholderToValue[placeholder].length > 1
      })
    }
  },

  methods: {
    initTemplate () {
      this.incrementTemplateViewed()
      this.getTemplate()
    },

    async getTemplate () {
      const ref = firestore.collection('templates').doc(this.templateId)

      const doc = await ref.get()

      // if (typeof templateObj !== 'undefined') {
      // this.template = templateObj
      if (doc.exists) {
        this.template = doc.data()
      } else {
      // show 404
        this.notFound = true
      }

      await this.setRecipientTemplate()

      this.isLoading = false
    },
    async setRecipientTemplate () {
      if (this.hasRecipientOptions) {
        this.recipientTemplate = this.template.recipient_options
      }

      if (this.hasRecipientTemplate) {
        const templatePath = `recipient_templates/${this.template.recipient_template}`
        const ref = await firestore.collection(templatePath).get()

        const t = {}

        ref.docs.forEach(doc => {
          t[doc.id] = doc.data()
        })

        this.recipientTemplate = t
      }
    },
    updatePlaceholder (placeholder, val) {
      Vue.set(this.placeholderToValue, placeholder, val)
    },
    addTemplateToScript () {
      document.head.appendChild(document.createElement('script')).innerHTML = `
        var templateObj = ${JSON.stringify(this.template)}
      `
    },
    objToQueryString (obj) {
      return Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&')
    },
    incrementTemplateViewed () {
      templatedViewed({ templateHashId: this.templateId })
    },
    trackEmailClick () {
      analytics.logEvent('emailBtnClicked', {
        templateId: this.templateId
      })
    }
  },
  watch: {
    templateExists (val, old) {
      if (old === false && val === true) {
        this.addTemplateToScript()
        this.rendered = true
      }
    }
  },
  components: {
    NotFound
  }
})
</script>

<style lang="scss" scoped>
.instructions-list {
  margin-bottom: 20px;
}
pre {
  white-space: pre-line;
}
</style>
